

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllStudent_Data } from "../../store/Actions/adminActions";

const AdminStudentDetails = () => {
  const dispatch = useDispatch();
  const AllStudent_Data = useSelector((state) => state.Admin.AllStundetList);
  const [filterOption, setFilterOption] = useState("");

  useEffect(() => {
    dispatch(GetAllStudent_Data());
  }, [dispatch]);

  const filteredStudents = useMemo(() => {
    let filteredData = AllStudent_Data ? [...AllStudent_Data] : [];

    switch (filterOption) {
      case "NOPLAN":
        filteredData = filteredData?.filter(
          (student) => student?.buyPlan === false
        );
        break;
      default:
        if (filterOption) {
          filteredData = filteredData?.filter(
            (student) => student?.Active_Plan === filterOption
          );
        }
        break;
    }

    console.log(filterOption, "*--------------filterOption");
    console.log(" the Filter options", filteredData);

    return filteredData?.sort((a, b) => {
      const dateA = new Date(a?.createdAt)?.getTime();
      const dateB = new Date(b?.createdAt)?.getTime();

      if (isNaN(dateA) && isNaN(dateB)) return 0; // both dates are invalid or absent
      if (isNaN(dateA)) return 1; // a has an invalid or absent date, b goes first
      if (isNaN(dateB)) return -1; // b has an invalid or absent date, a goes first
      return dateB - dateA; // both dates are valid, sort in descending order
    });
  }, [AllStudent_Data, filterOption]);

  return (
    <div>
      <div className="mb-4">
        <label
          htmlFor="filter"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Filter by Active Plan:
        </label>
        <select
          id="filter"
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">
            Please Filter the Table on the Basis of the Active plan
          </option>
          <option value="Monthly">Monthly</option>
          <option value="Yearly">Yearly</option>
          <option value="Half-Yearly">Half-Yearly</option>
          <option value="NOPLAN">NOPLAN</option>
        </select>
      </div>
      {filteredStudents?.length > 0 ? (
        <>
          <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Child name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Parents Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Buy Plan
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Logged In with Google
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      createdAt
                    </th>
                    <th scope="col" className="px-6 py-3">
                      filterOption
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents?.map((student, index) => (
                    <tr
                      key={index}
                      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {student?.Children_Name}
                      </th>
                      <td className="px-6 py-4">{student?.Username}</td>
                      <td className="px-6 py-4">
                        {student?.buyPlan === false ? "No" : "Yes"}
                      </td>
                      <td className="px-6 py-4">
                        {student?.FromGoogle === false ? "No" : "Yes"}
                      </td>
                      <td className="px-6 py-4">
                        <a
                          href="#"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          {student?.Email}
                        </a>
                      </td>
                      <td className="px-6 py-4">{student?.createdAt}</td>
                      <td className="px-6 py-4">{filterOption}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <tfoot>
                <tr className="w-full text-sm text-center rtl:text-center text-gray-500 dark:text-gray-400 font-semibold text-gray-900 dark:text-white shadow-md sm:rounded-lg">
                  <th
                    scope="row"
                    className="w-half px-6 py-3 text-base text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                  >
                    Total users are present in the Category of table
                  </th>
                  <td className="px-6 py-3 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    {filteredStudents?.length}
                  </td>
                </tr>
              </tfoot>
            </div>
          </div>
        </>
      ) : (
        <p>No students signed up this week.</p>
      )}
    </div>
  );
};

export default AdminStudentDetails;

